
/**
 * @name: detail
 * @author: Gzm
 * @date: 2023-07-27 15:15
 * @description：detail
 * @update: 2023-07-27 15:15
 */

import {Component, Vue} from "vue-property-decorator"
import {ICrudOption} from "@/types/m-ui-crud"
import {orderRefundDetailApi, orderRefundGoodsListApi} from "@/apis/afterSales/refunded"
import config from "@/config";
import {IOrderRefunded, RootObjectOrderRefundGoodsVoList} from "@/apis/afterSales/refunded/types";
import {saleOrderDetailApi} from "@/apis/sale/order";

@Component({})
export default class DetailsPage extends Vue {
  config = config;
  // 表格加载状态
  tableLoading: boolean = false;
  amount: string = ''
  // 表格数据
  tableData: any[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  id: string = ''
  // 查询参数
  queryForm: any = {
    page: 1,
    limit: 10,
  }
  // 表单数据
  modelForm: Partial<IOrderRefunded> = {}
  // 表单数据
  detailForm: Partial<IOrderRefunded> = {
    orderRefundDtlVoList: []
  }

  // 商品明细
  detailDialog = false
  goodsDetailData: Partial<RootObjectOrderRefundGoodsVoList[]> = []

  // 配置信息
  crudOption: ICrudOption = {
    menu: false,
    searchBox: false,
    column: [
      {
        label: "商品ID",
        prop: "goodsId",
        align: "center",
      },
      {
        label: "商品名称",
        prop: "goodsName",
        align: "center",
      },
      {
        label: "售价",
        prop: "price",
        align: "center",
      },
      {
        label: "数量",
        prop: "qty",
        align: "center",
      },
      {
        label: "商品金额",
        prop: "goodsPrice",
        align: "center",
        help: "售价*数量"
      },
    ]
  }

  /**
   * 查看详情
   */
  openDetail() {
    saleOrderDetailApi(this.id as string).then(e => {
      if (e) {
        this.detailForm = e;
      }
    })
  }

  created() {
    const {id} = this.$route.query;
    this.id = id;
    this.openDetail()
  }
}
